@use "../mixins/mq";

.logos {

	--logo-animation-speed: 33s;

	&__track {
		overflow-x: hidden;
		overflow-y: hidden;
		padding: 0 var(--wrapper-gap);
		max-width: 200rem;
		margin: auto;
		-webkit-mask-image: linear-gradient(90deg,  transparent 0%, black 5rem , black calc(100% - 4rem), transparent  100% );
		mask-image: linear-gradient(90deg,  transparent 0%, black 5rem , black calc(100% - 4rem), transparent  100% );

		&--no-mask {
			@include mq.mq($from: 2000px) {
				-webkit-mask-image: none;
				mask-image: none;
			}
		}
	}

	&__list {
		list-style: none;
		margin: 0;
		padding: 0;
		display: flex;


		@include mq.mq($from: large) {
			justify-content: center;
		}
		&--no-center {

			@include mq.mq($from: large) {
				justify-content: flex-start;

			}
		}


		

	}

	&__item {
		margin: 0;
		padding: 0;


		&--clone {
			display: var(--logos-item-clone-display,block);
		}

	}

	&__box {
		--img-object-fit: contain;
		--img-width: 20rem;
		--img-height: 100%;

		padding: 1.5rem;
		display: grid;
		justify-items: stretch;
		align-items: stretch;
		background-color: var(--color-white);
		transition: background-color var(--animationDuration) var(--animationEase);


		border-radius: 1rem;
		aspect-ratio: 9/5;
		
		width: 100%;

		&--link {
			outline-offset: -0.2rem;
			&:hover,
			&:focus {
				background-color: var(--color-secondary-2);
			}			
		}

	}

	@include mq.mq($from: medium) {
		&__box {
			padding: 1.5rem;
		}

	}
	

}
