.chips {
    display: inline-block;
    border-radius: 5rem;
    text-transform: uppercase;
    font-size: var(--chips-font-size);
    line-height: 1.5625;
    padding: var(--chips-padding,0.25em 0.8em);
    letter-spacing: var(--chips-letter-spacing,.1rem);
    background-color: var(--chips-bg-color);
    color: var(--chips-color);
    text-decoration: none;
    white-space: nowrap;
    font-weight: 600;
    transition: background-color var(--animationDuration) var(--animationEase), color var(--animationDuration) var(--animationEase);


    &--no-text-transform {
        text-transform: none;
    }
    &--large {
        --chips-font-size: var(--chips-large-font-size);
        --chips-padding: var(--chips-large-padding);
        --chips-large-letter-spacing: var(--chips-letter-spacing);
    }

    &--dark {
        --chips-color: var(--color-white);
        --chips-bg-color: var(--chips-dark-bg-color);
    }

    &--secondary {
        --chips-color: var(--color-white);
        --chips-bg-color: var(--color-secondary-1);
    }

    &--active {
        --chips-color: var(--chips-active-color);
        --chips-bg-color: var(--chips-active-bg-color);
    }

}