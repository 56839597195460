@use "../mixins/mq";

.section {
	position: relative;
	overflow: hidden;
}

.section--spacing {
	padding-top: 4rem;
	padding-bottom: 4rem;

	@include mq.mq($from: large) {
		padding-top: 12rem;
		padding-bottom: 12rem;
	}
}


.section--spacing-top {
	padding-top: 4rem;

	@include mq.mq($from: large) {
		padding-top: 12rem;
	}
}


.section__title {
	margin-bottom: 4rem;
}
