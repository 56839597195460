@use "../_base/svg";
@use "../_config/colors";
@use "../mixins/background-svg";
@use "../mixins/mq";


.three_columns__description {

  font-size: var(--font-size-h4);
  line-height: var(--line-height-h4);
  letter-spacing: var(--letter-spacing-h4);
  margin-bottom: var(--spacing-50);
}

.three_columns__item-link {
  text-decoration: none;
  color: var(--color-black);
}

.three_columns__item-content {
  /*padding: 3rem 0 4rem;*/
  position: relative;

  * + * {
    margin-top: 2rem;

    @include mq.mq($from: large) {
      margin-top: 3rem;
    }
  }
}

.three_columns__list {
  grid-row-gap: 2.5rem;
  @include mq.mq($from: large) {
    grid-row-gap: 5rem;
  }

  &--center {

  }
}

.three_columns__footer {
  margin-top: 3rem;
}

.three_columns__item {
  background-color: var(--color-white);
  border-radius: var(--border-radius);
  padding: var(--spacing-50);
  //margin-bottom: var(--spacing-25);
  position: relative;
  overflow: hidden;


  p {
    margin-bottom: 0;
    margin-top: 1.8rem;
  }

  @include mq.mq($from: large) {
    padding: var(--spacing-80);
   // margin-bottom: var(--spacing-50);
  }

  &--red {
    background-color: var(--color-primary-1);
    color: var(--color-white);
    

  }

  &--gray {
    background-color: var(--color-secondary-1);
    color: var(--color-grey-100);
  



  }

  &--white {
    background-color: var(--color-white);
    color: var(--color-black);
   

  }

  &--black {
    background-color: var(--color-black);
    color: var(--color-grey-100);
  

  }

  &--has-bg {
    color: var(--color-white);

  }

  &--backgroundimage {
    color: var(--color-white);

  
  }

  &--left {
    text-align: left;
 
  }

  &--center {
    text-align: center;


    .three_columns__item-content {
      text-align: center;
    }
  }

  .figure {
    &-three {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      &:after {
        background-color: var(--color-black);
        opacity: 0.65;
      }
    }
  }
}