@use "../mixins/mq";
@use "../atoms/grid";

.fullscreen {
	
	&__text {
		h1, h2, h3, h4, h5, h6, p {
			margin-bottom: var(--spacing-25);
			margin-top: 0;
		}
		.action {

			margin: 0;
			
		}
		:last-child {
			margin-bottom: 0;
		}
	}

	&-media {
		display: flex;
		justify-content: center;
		align-items: flex-end;
		@include mq.mq($from: large) {
			min-height: 80vh;
		}
		
	}

	.figure {

		width: 100%;
		height: 41rem;
		border-radius: var(--border-radius);
		margin-top: var(--spacing-50);
		@include mq.mq($from: large) {
			height: 60rem;
		}
		@include mq.mq($from: large) {
			height: 80rem;
		}

	}
}
