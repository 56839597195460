@keyframes fadeInZoom {
    from {
        opacity: 0;
        transform: scale(.8);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes spinning {
    from {
        transform: rotate(0);
    }

    to {
        transform: rotate(720deg);
    }
}

@keyframes graphic-animation {
    from {
        transform: rotate(-360deg);
        --end-conic-gradient: 80deg;
        opacity: .5;
    }

    to {
        --end-conic-gradient: 360deg;
        transform: rotate(0);
        opacity: 1;
    }
}

@keyframes unblur {
    from {
        filter: blur(5rem);
        opacity: 0;
    }

    33% {
        filter: blur(5rem);
        opacity: 0;
    }

    to {
        filter: blur(0);
        opacity: 1;
    }
}

@keyframes logo-animation {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(calc(100% / -3));
    }
}