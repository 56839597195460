@use "../mixins/mq";


.ctabox {
  background-color: var(--color-primary-1);
  border-radius: var(--border-radius);
  background-image: var(--body-bg-grey);
  background-repeat: no-repeat;
  background-position: left -2rem bottom -1rem;
  background-size: 40rem auto;
  padding: 2.5rem 2rem;
  padding-bottom: 30rem;

  position: relative;
  overflow: hidden;


  &--background {
    min-height: 80rem;
  }

  &--overlay-and-background {
    background:  rgba(194, 193, 202, 1);
    min-height: auto;
    --ctabox-figure-top: auto;
    --ctabox-figure-mask: linear-gradient(transparent, black 3rem);
    background-position: bottom left;
    background-size: 30rem;
  }



  &__overlay {
    background-color: var(--color-white);
    border-radius: var(--border-radius);
    padding: 2.5rem;
    z-index: 1;
    position: relative;
    max-width: var(--ctabox-overlay-max-width, none);
/*
    p {
      display: flex;
      gap: 1.3rem;
      flex-wrap: wrap;
    }
    */
  }

  &__figure {
    position: absolute;
    left: 0;
    right: 0;
    top: var(--ctabox-figure-top, 0);
    bottom: 0;
    min-height: 30rem;
    -webkit-mask-image: var(--ctabox-figure-mask);
    mask-image: var(--ctabox-figure-mask);
  }

    /* large */
  @include mq.mq($from: large) {
    padding: 8rem;
    background-position: right center;
    background-size: auto 120%;
    --ctabox-overlay-max-width: 63rem;
    --ctabox-figure-top: 0;
    --ctabox-figure-mask: none;
    min-height: 80rem;
    display: grid;
    align-items: stretch;

    &__container {
      display: grid;
    }

    &__overlay {
      display: flex;
      padding: 8rem;
      flex-direction: column;
      justify-content: center;
    }
  }

}