@use "../mixins/mq";


.c-timeline {
  background-color: var(--color-white);
  border-radius: var(--border-radius);
  padding: var(--spacing-50) var(--wrapper-gap) 10rem;

  position: relative;

  &-description {
  
    strong {
      color: var(--timeline-description-strong-color);

      ~ strong {
        --timeline-description-strong-color: var(--color-secondary-1);

        ~ strong {
          --timeline-description-strong-color: var(--color-primary-1);
        }
      }

    }

  }

  @include mq.mq($from: large) {
    padding-top: var(--spacing-100);
    padding-bottom: 20rem;
  }
  &__container {
    position: relative;
  }

  &-supertitle {

    letter-spacing: 0.153rem;
    font-weight: bold;
    color: var(--color-secondary-1);
    text-align: center;
    text-transform: uppercase;
    font-size: 1.8rem;
    line-height: 2.8rem;
    @include mq.mq($from: large) {
      font-size: 2.3rem;
      line-height: 3.5rem;
    }
  }
  &-description {
    font-size: 2.7rem;
    text-align: center;
    margin-top: var(--spacing-15);
    margin-bottom: var(--spacing-50);
    font-weight: var(--font-weight-light);
    line-height: 3.7rem;
    @include mq.mq($from: large) {
      font-size: 8rem;
      line-height: 10rem;
      margin-top: var(--spacing-25);
      margin-bottom: var(--spacing-100);

    }
  }
  &-row {
    counter-increment: timeline;

    margin-top: var(--spacing-25);
    @include mq.mq($from: large) {
      margin-top: -5rem;
    }
    &--first {
      margin-top: 0;
    }

  }
  &-item {
    background-color: var(--color-grey-100);
    border-radius: 1rem;
    text-align: center;


    &__number {
      background-image: url("/web/assets/images/footer-oval.png");
      background-repeat: no-repeat;
      background-size: contain;
      text-align: center;
      width: 6rem;
      height: 6rem;
      margin: 1.5rem auto 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      @include mq.mq($from: large) {
        width: 8rem;
        height: 8rem;
        margin: 2rem auto 0 auto;
      }
      &:before {

        content: counter(timeline);
        text-align: center;
        font-size: 3rem;
        line-height: 4.8rem;
        font-weight: bold;
        @include mq.mq($from: large) {
          font-size: 4rem;
          line-height: 4.8rem;
        }
      }
    }
    &__title {
      color: var(--color-black);
      font-size: 1.6rem;
      line-height: 2.5rem;
      font-weight: var(--font-weight-bold);
      text-align: center;
      text-transform: uppercase;
      margin-top: 1.5rem;
      padding: 0 2.4rem;
      @include mq.mq($from: large) {
        margin-top: 2rem;
      }
    }
    &__description {
      font-size: var(--globalFontSize);
      line-height: 2.8rem;
      text-align: center;
      color: var(--color-black);
      margin-top: 0.5rem;
      padding: 0 2.4rem 1.5rem 2.4rem;

      @include mq.mq($from: large) {
        margin-top: 1rem;
        padding: 0 2.4rem 2.4rem 2.4rem;
      }
    }
    &__responsibility {
      text-transform: uppercase;
      font-size: 1.4rem;
      font-weight: var(--font-weight-bold);
      line-height: 2.9rem;
      height: 2.9rem;
      letter-spacing: 0.1rem;
      color: var(--color-grey-100);
      text-align: center;
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem;
      @include mq.mq($from: large) {

      }
      &--kwaxx {
        background-color: var(--color-black);
      }
      &--customer {
        background-color: var(--color-primary-1);
      }
      &--both {
        background-color: var(--color-secondary-1);
      }
    }
  }

  &-link {
    padding: var(--spacing-50) 0;
    @include mq.mq($from: large) {
      padding: var(--spacing-100) 0;
    }
  }
  &__title {
    position: absolute;
    color: var(--color-grey-100);
    user-select: none;
    width: 144rem;
    max-width: 100%;
    max-width: calc( 100% - 5rem);
    font-weight: var(--font-weight-bold);

    z-index: 0;
    left: 2.5rem;
    right: 2.5rem;
    bottom: 0;
   
  }
}