@use "../mixins/mq";
@use "vars";

html {
	font-size: 62.5%;
	text-size-adjust: none;
	scroll-behavior: smooth;
	scroll-padding: var(--scroll-padding);
	background-color: transparent;

}

@media screen and (prefers-reduced-motion: reduce) {
	html {
		scroll-behavior: auto;
	}
}
body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;

	color: var(--body-color);
	font-family: var(--bodyFontFamily, vars.$bodyFontFamily);
	font-size: var(--globalFontSize);
	line-height: 1.7;

	//padding-top: var(--body-padding-top,0);
	hyphens: manual;
	font-variant: none;
	position: relative;

	word-break: break-word;

	background-color: var(--body-bg-color);

	padding-top: var(--scroll-padding);

}

.body-bg-x {
	background-image: var(--body-bg);
	background-repeat: no-repeat;
	background-size: var(--body-bg-size);
	background-position: var(--body-bg-position);
}

.dark {
	--body-bg-color: var(--color-black);
	--body-color: var(--color-white);
	--color-meta-nav: var(--color-secondary-1);
	--body-bg: var(--body-bg-dark);
	--hamburger-color: var(--color-white);
	--header-border-color: var(--color-grey-400);

	&--only-header {
		--body-bg: none;
		--layout-bg-color: var(--color-grey-100);
		--layout-color: var(--color-black);
		--header-row-bg-color: var(--color-black);
		--header-row-opacity: 1;
	}
}

