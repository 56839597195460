@use "../mixins/mq";

.textImage__item {
	--spacing-h2: 0;

	&:not(:first-child) {
		margin-top: 12.8rem;

		@include mq.mq($from: large) {
			margin-top: 10rem;
		}
	}


}

.textImage__item-text {


	@include mq.mq($from: large) {


		.row--reverse & {
			padding-left: 0;
		}
	}
	p {
		margin-top: 0;
		margin-bottom: var(--spacing-25);
		&:last-of-type {
			margin-bottom: 0;
		}
		.action {
			margin-top: var(--spacing-25);
		}
	}
	h1,h2,h3,h4,h5 {
		margin: 0;
	}
	h2 {

		font-size: 2.4rem;
		line-height: 3.4rem;
		margin-bottom: var(--spacing-15);
		@include mq.mq($from: large) {
			margin-bottom: 2rem;
			font-size: 5rem;
			line-height: 6.5rem;

		}
	}
	h3 {
		margin-bottom: 1rem;
	}
}
.textImage__item-supertitle {
	text-transform: uppercase;
	font-size: var(--font-size-h5);
	line-height: 4rem;
	letter-spacing: 0.153rem;
	font-weight: bold;
	color: var(--color-secondary-1);
	margin-bottom: var(--spacing-15);
}
.textImage__item-image {
	@include mq.mq($from: large) {
		margin-bottom: auto;
	}
	.figure-two {
		border-radius: var(--border-radius);
		margin-bottom: var(--spacing-25);
		@include mq.mq($from: large) {
			margin-bottom: 0;
		}
	}
}