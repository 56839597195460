@use "../_base/svg";
@use "../_config/colors";
@use "../_base/typography";
@use "../mixins/background-svg";
@use "../mixins/mq";

.lang-chooser {
  position: relative;

  &__open {
    color: var(--color-grey-100);
    font-size: 1.6rem;
    letter-spacing: 0.5px;
    line-height: 2.5rem;
    font-weight: bold;
    cursor: pointer;

    span {
      display: block;
      height: 100%;
      &:after {
        content: '';
        width: 2rem;
        height: 2rem;
        display: inline-block;
        top: 0.3rem;
        position: relative;
        @include background-svg.params(svg.$svg-chevron-down, transparent, center, center, no-repeat, 1.2rem, .8rem, colors.$color-white);
      }
    }

  }

  &:focus,
  &:hover {
    .lang-chooser__list {
      display: block;
    }
  }
}

.lang-chooser__list {
  display: none;
  position: absolute;
  left: 0;
  top: 100%;
  max-height: unset;
  flex-direction: column;
  opacity: 1;
  transform: translate3d(0rem, 0rem, 1px);
  white-space: nowrap;
  box-shadow: 0 2px 20px 0 rgba(26, 25, 25, 0.1254901961);
  background-color: var(--color-secondary-1);
  padding: 1.3rem;
  border-radius: 1rem;
  gap: 1.5rem;
  z-index: 1;
}

.lang-chooser__item {
  position: relative;
  margin-top: 0;
  padding-left: 0;


  color: var(--color-grey-100);
  font-size: 1.6rem;
  letter-spacing: 0.5px;
  line-height: 4rem;
  font-weight: bold;

  .nav__link {
    color: var(--color-grey-100);
  }

  &--is-active,
  &--is-active:hover + & {
    display: block;
  }

  &--is-active {
    span {
      border-bottom: 0.1rem solid var(--color-primary-1);
    }
  }

  &:not(:first-child) {
    margin-top: 0;
  }


  .nav__link {
    .not-touch & {
      &:hover {
        color: var(--color-primary-1);
      }
    }
  }
}
