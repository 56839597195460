@use "../mixins/mq";

.c-quote {
  text-align: center;
  &__quote {
    font-size: 2rem;
    line-height: 3rem;
    color: var(--color-black);
    font-weight: var(--font-weight-light);
    @include mq.mq($from: large) {
      font-size: 3.2rem;
      line-height: 4.2rem;
    }


    &:before {
      content: '';
      -webkit-mask-image: url("/web/assets/images/quote.svg");
      mask-image: url("/web/assets/images/quote.svg");

      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
      -webkit-mask-size: contain;
      mask-size: contain;
      width: 3.6rem;
      height: 3.5rem;
      background-repeat: no-repeat;
      background-size: contain;
      display: block;
      margin: 0 auto;
      background-color: var(--color-white);
      margin-bottom: var(--spacing-25);


    }
  }
  &__logo {
    margin-top: var(--spacing-50);
    figure {
      max-width: 20rem;
      margin: 0 auto;
    }

  }
  &__name {
    color: var(--color-black);
    font-weight: bold;
    letter-spacing: 0.05rem;
    font-size: 1.8rem;
    line-height: 2.8rem;
    margin-top: var(--spacing-15);
  }
  &__function {
    color: var(--color-black);
    font-size: 1.8rem;
    line-height: 2.8rem;
    margin-top: var(--spacing-5);
  }
}