@use "../_base/svg";
@use "../_config/colors";
@use "../mixins/background-svg";
@use "../mixins/mq";

.nav {
	display: inline-flex;
}

.nav__toggle.nav__toggle--action {
	position: absolute;
	top: 0;
	right: 0;
	width: 5rem;
	height: 5rem;
	transform-origin: center center;
	transition: transform var(--animationDuration) var(--animationEase);
	color: inherit;
    display: grid;
    justify-content: center;
    align-items: center;
	.icon {
		color: var(--color-black);
	}


	.nav__item--parent-opened & {
		transform: rotate(180deg);
	}

	@include mq.mq($from: large) {
		display: none;
		visibility: hidden;
	}
}

.nav__wrap {
	display: flex;
	flex-wrap: wrap;
	margin: 0;
	padding: 0;
	list-style: none;
	align-items: center;

	&--parent {
		.nav--main-nav &,
		.nav--meta-nav & {
			@include mq.mq($from: large) {
				justify-content: flex-end;
			}
		}
	}

	&--child {
		:first-child {
		margin-top: 0;
		}
		max-height: 0;
		transition: max-height 0.5s var(--animationEase);
		overflow: hidden;

		.nav__item--parent-opened > & {
			max-height: 150rem;
		}

		@include mq.mq($from: large) {
			position: absolute;
			left: 0;
			top: 100%;
			display: none;
			max-height: unset;
			flex-direction: column;
			padding: 2.6rem;
			opacity: 0;
			transform: translate3d(0rem, 1.2rem, 1px);
			white-space: nowrap;
			background-color: var(--color-white);
			box-shadow: 0 2px 20px 0 #1A191920;
			background-color: var(--color-secondary-1);
			padding: 1.3rem;
			border-radius: 1rem;
			gap: 1.5rem;


		}
	}

	&.nav__wrap--visible {
		display: flex;
		z-index: 9998;
		visibility: visible;
	}
}

.nav__item {
	position: relative;
	padding-left: 0;

	&:not(:first-child) {
		margin-top: 0;
	}

	.icon-chevron-down,
	.icon-chevron-down svg {
		@include mq.mq($from: large) {
			width: 20px;
			height: 12px;
		}
	}
}

.nav__item--child {

	width: 100%;

	&:hover {
		/*@include background-svg.params(svg.$svg-arrow-right, transparent, right, center, no-repeat, 22px, 22px, colors.$color-primary-1);*/
	}
}

.nav__link {
	text-decoration: none;
	color: var(--color-black);

	&.is-active {
		color: var(--color-primary-1);
	}
}

.nav__link--child {
	font-size: 1.6rem;
	line-height: 2.5rem;
	letter-spacing: 0.5px;
	color: var(--color-white);
}
