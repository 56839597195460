@use "../mixins/mq";
@use "../atoms/grid";

.text {
  figure {
    display: none;
  }
  figure:not(.iframe__poster) {
    background-color: var(--color-white);
    border-radius: var(--border-radius);
    margin-bottom: 5rem;

    img {
      border-radius: var(--border-radius);

    }

    figcaption {
      font-size: 1.6rem;
      line-height: 1.5625;
      padding: 0.5rem 2.5rem;
    }
  }

  .iframe_wrapper {
    .iframe__poster img {
      border-radius: var(--border-radius);

    }


  }

}
.iframe-container {
  iframe {
    border-radius: var(--border-radius);
  }
}