@use "../mixins/mq";
@use "../utils/visually-hidden";

/* shared */ 

.header {
  position: fixed;
  z-index: 50;
  width: 100%;
  transition: background-color var(--animationDuration) var(--animationEase), transform var(--animationDuration) var(--animationEase);
  transform: translateY(0);
  top: 1rem;
  border-radius: var(--header-border-radius) var(--header-border-radius) ;
  box-shadow: 0 0 0 50rem var(--color-meta-nav);

  box-shadow: 0px -4rem 0rem 2rem var(--color-meta-nav);
 

  &__container {
    padding-left: var(--wrapper-gap);
    padding-right: var(--wrapper-gap);
  }

  &__nav-main {
    padding: 0;
    margin: 0;
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 5rem;
    gap: 0 4rem;

      &:after {
        content: "";
        display: block;
        background-color: var(--body-bg-color);
        position: absolute;
        z-index: 4;
        inset: 0;
        border-radius: var(--header-border-radius);
        opacity: var(--header-row-opacity,0);
        transition: opacity 0s 1s, border var(--animationDuration) var(--animationEase);
        border: .1rem solid var(--header-row-border-color, transparent);
        .header__nav--opened & {
          opacity: 1;
          transition: opacity 0s, border var(--animationDuration) var(--animationEase);
        }
      }
    
  }


  &--detached {
    background-color: var(--body-bg-color);
  }


  &__home-link {
    display: inline-flex;
    color: inherit;
    position: relative;
    z-index: 5;
    width: 10.6rem;
  }

  &__hubspot-link {
    border: none;
    a {
      height: 4.5rem;
      border: 0.1rem solid currentColor !important;
      border-radius: 2.3rem;
      color: inherit;
      text-decoration: none;
      font-weight: bold;
      letter-spacing: 0.5px;
      line-height: 4.5rem;
      padding: 0;
      .nav__text {
        padding: 0 1.35rem;
      }

    }

  }

  &__nav-meta {

    width: 100%;
    height: 4rem;
    align-items: center;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    
  }

  &__hamburger {
    z-index: 5;
    position: relative;
    display: none;
  }
}

@include mq.mq($from: large) {
.header {

  --wrapper-gap: 5rem;

    &__home-link {
      width: 20rem;
    }
  

    top: 4rem;

    &--detached {
      --header-row-border-color: var(--header-border-color);
    }
    
    &__row {
      height: 9.4rem;

      &:after {
        --header-row-opacity: 1;
        z-index: -1;
        background: var(--header-row-bg-color,transparent);
      }
    }

    &__nav-meta {
      margin-top: -4rem;
    }

    &--scroll {
      /*transform: translateY(-3.1rem);*/
    }

  }
}

/* small only */

@include mq.mq($until: large) {

.header {


  &__hamburger {
    display: block;
  }
  .lang-chooser {
    max-width: var(--mobile-menu-max-width);
    margin: 0 auto;
    width: 100%;

  }



  &__nav {

    position: fixed;
    top: 2.5rem;
    right: 0;
    width: 100vw;
    height: calc(100vh - 2.5rem);
    padding-top: 9rem;
    padding-bottom: 5rem;
    padding: 0;
    z-index: 1;
    transform: translate3d(-100%, 0, 0);
    background-color: var(--color-white);
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    transition: transform var(--animationDuration) var(--animationEase);
    flex-direction: column;
    display: flex;
    flex-direction: column;
    background-color: var(--color-grey-100);
    z-index: 3;
    //border-top-left-radius: var(--border-radius);
    //border-top-right-radius: var(--border-radius);


    .header__nav--opened & {
      transform: translate3d(0, 0, 0);

    }


  }
  &__nav-meta {
    width: 100%;
    order: 2;
    background-color: var(--color-meta-nav);
    padding: var(--spacing-50) 0;
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
    height: auto;
    display: flex;
    flex-direction: column;

    flex-grow: 1;
    margin-top: var(--spacing-100);
    justify-content: center;
  }
}
.header__nav {



    .header__nav-meta {
      width: 100%;
      order: 2;
      background-color: #262626;

      border-top-left-radius: 2rem;
      border-top-right-radius: 2rem;
      height: auto;
      display: flex;
      flex-direction: column;
      padding: var(--spacing-15) var(--wrapper-gap) 11rem;
      flex-grow: 1;
      margin-top: var(--spacing-100);
      justify-content: center;
    }

    .nav--meta-nav {

      max-width: var(--mobile-menu-max-width);
      width: 100%;
      margin: 0 auto;
      height: auto;
      padding: 0 0 var(--spacing-25);


      .nav__item--parent {
        margin: 0;
        margin-top: var(--spacing-25);

      }
    }


    .header__nav-main {
      color: var(--color-black);
      width: 100%;
      order: 1;
      flex-direction: column;
      height: auto;
      align-items: flex-start;
      padding: 0 var(--wrapper-gap);
      padding-top: 0.8rem;
      max-width: var(--mobile-menu-max-width);
      margin: 0 auto;

      .nav--main-nav {
        padding-top: var(--spacing-50);

        .nav__item--parent {
          min-height: var(--spacing-50);
          line-height: var(--spacing-50);
          border: none;
        }
      }

    }

    .header__hubspot-link {
      margin-left: 0;
      margin-top: var(--spacing-25);
      width: auto;

        a {
          padding-inline: 1rem;
        }
    }
  }
}