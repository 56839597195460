@use "../mixins/mq";
@use "../_base/vars";
@use "../_config/colors";

.c-hubspot__wrapper {
  position: relative;
  max-width: 134.4rem;
  width: calc(100% + 2.4rem);
  margin-left: -1.2rem;
  margin-right: -1.2rem;
  border-radius: 1.7rem;
  color: var(--color-white);
  box-shadow: 2px 20px 60px -8px rgba(14, 43, 45, .4);
  background-color: var(--color-primary-2);

  @include mq.mq($from: hubspotLarge) {
    display: grid;
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    grid-template-columns: 45rem 1fr;
    grid-template-areas: "text embed";
    //height: 654px;
    height: 638px;
    background: linear-gradient(to right, var(--color-primary-2) 0%, var(--color-primary-2) 50%, var(--color-white) 50%, var(--color-white) 100%);
  }

  .icon-communication {
    position: absolute;
    top: 0;
    left: 3.8rem;
    width: 15.3rem;
    color: var(--color-primary-1);
    transform: translateY(-4.3rem);

    @include mq.mq($from: hubspotLarge) {
      left: 5.5rem;
      width: 21.5rem;
      transform: translateY(-5.5rem);
    }
  }

  /*&:before {
    position: absolute;
    left: 0;
    top: 16px;
    content: '';
    display: block;
    width: 100%;
    height: 654px;
    background: linear-gradient(to right, var(--color-primary-2), var(--color-white));
    z-index: -1;
  }*/
}

.c-hubspot__text {
  grid-area: text;
  align-self: center;
  padding: 13rem 3.8rem 6rem;

  @include mq.mq($from: hubspotLarge) {
    padding: 5.5rem;
  }
}

.c-hubspot__lead {
  margin-top: 0.8rem;
}

.c-hubspot__team {
  margin-top: 3.5rem;
  margin-bottom: 0.6rem;
}

.c-hubspot__embed {
  position: relative;
  grid-area: embed;
  justify-content: flex-end;
  overflow: hidden;
  border-radius: 17px;

  &:before,
  &:after {
    position: absolute;
    content: '';
  }

  &:before {
    left: 38px;
    top: 1px;
    display: block;
    height: 1px;
    width: calc(100% - 76px);
    background-color: #F2F0EE;
    opacity: 0.8;
    z-index: 2;

    @include mq.mq($from: hubspotLarge) {
      left: 9px;
      top: 65px;
      width: 1px;
      height: calc(100% - 110px);
    }
  }

  // pseudo element to hide default shadow
  &:after {
    left: 12px;
    top: 20px;
    height: 100%;
    width: 33px;
    display: none;
    background-color: var(--color-primary-2);
    z-index: 1;

    @include mq.mq($from: hubspotLarge) {
      display: block;
    }
  }
}

.c-hubspot__embed-inner {
  position: relative;
  //height: 650px;
  height: 605px;

  @include mq.mq($from: hubspotMedium) {
    height: 660px;
  }


  @include mq.mq($from: hubspotLarge) {
    height: auto;
    margin-top: -16px;
    margin-right: -43px;
    background: linear-gradient(to right, colors.$color-primary-2, colors.$color-primary-2 calc(50% + 1px), colors.$color-white calc(50% + 1px), colors.$color-white);
  }
}

.meetings-lib {

  .c-hubspot__embed {
    margin: 0;
  }
}