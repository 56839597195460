@use "../_base/svg";
@use "../_config/colors";
@use "../_base/typography";
@use "../mixins/background-svg";
@use "../mixins/mq";

.teamChannel {

}

.c-teamChannel {
  &-header {
    &__title {
      color: var(--color-grey-100);
      margin-top: 0;
    }

    &__supertitle {
      margin-bottom: 1.6rem;
      color: var(--color-secondary-1);
      text-transform: uppercase;
      margin-top: 0;
    }
  }

  &__image {
    width: 100%;
    img {
      width: 100%;
    }
  }

  &__list {
    margin: 5rem 0;
   
  }

  &__filter {
    margin: 5rem 0;
  }

/*
  &__filter {

    @include mq.mq($from: large) {
      height: 27rem;
    }

    &-items {
      display: flex;

      justify-content: center;

      padding: var(--spacing-100) 0;
      flex-wrap: wrap;
      column-gap: 1.5rem;
      row-gap: 2.5rem;
      @include mq.mq($from: large) {
        flex-wrap: initial;
        gap: 2rem;

      }

      &-option {
        font-size: 1.8rem;
        height: 3.5rem;
        line-height: 3.5rem;
        padding: 0 1.2rem;

        font-weight: var(--font-weight-regular);

        letter-spacing: 0.153rem;

        background-color: var(--color-grey-100);
        color: var(--color-black);
        border-radius: 3.5rem;
        text-align: center;

        cursor: pointer;
        text-transform: uppercase;

        @include mq.mq($from: large) {
          font-size: 2.3rem;
          padding: 0 2rem;
          height: 7rem;
          line-height: 7rem;
          font-weight: var(--font-weight-bold);
        }

        &--active {
          background-color: var(--color-primary-1);
          color: var(--color-grey-100);
        }
      }
    }
  }
*/
  &-listitem {
    margin-bottom: 5rem;

    animation: fadeInZoom .5s ease-in-out;

    @include mq.mq($from: large) {
      margin-bottom: 8.6rem;
    }
    &__wrapper {
      position: relative;
      border-radius: var(--border-radius);
      overflow: hidden;
    }
    &__image {
      width: 100%;

     
    }

    &__country {


      position: absolute;
      left: 1.5rem;
      bottom: 1.5rem;
   
      @include mq.mq($from: large) {
        left: 2rem;
        bottom: 2rem;
      }
    }

    &__name {
      font-size: 1.7rem;
      letter-spacing: 0.047rem;
      line-height: 2.8rem;

      color: var(--color-grey-100);

      font-weight: var(--font-weight-regular);
      margin-top: var(--spacing-15);
      @include mq.mq($from: large) {
        font-size: 1.8rem;
        letter-spacing: 0.05rem;
        line-height: 2.8rem;
        font-weight: var(--font-weight-bold);
      }
    }

    &__function {
      font-size: 1.7rem;
      letter-spacing: 0.047rem;
      line-height: 2.8rem;


      color: var(--color-grey-100);

      font-weight: var(--font-weight-regular);
      margin-top: var(--spacing-5);
      @include mq.mq($from: large) {
        font-size: 1.8rem;
        letter-spacing: 0.05rem;
        line-height: 2.8rem;
      }
    }

    &__linktree {
      display: flex;
      gap: 1.9rem;
      margin-top: 2rem;
      flex-wrap: wrap;

      .action_old {
        width: 0;
        padding: 0;
        border: none;
        background-color: var(--color-primary-1);
        color: var(--color-grey-100);
        margin-right: 5rem;

        span {
          display: none;
        }

        &:after {
          width: 5rem;
          height: 5rem;
        }
        &:before {
          opacity: 0;
          background-color: transparent;
        }

        &-mail, &-phone {
          &:hover {
            padding: 0 2rem;
            width: auto;

            span {
              display: initial;
            }
          }
        }

        &-mail {
          &:after {

              @include background-svg.params(svg.$svg-mail, transparent, center, center, no-repeat, 2.5rem, 2.5rem, colors.$color-grey-100);
              background-color: var(--color-primary-1);

            background-color: var(--color-primary-1);
          }
          &:hover {
            &:after {
                @include background-svg.params(svg.$svg-mail, transparent, center, center, no-repeat, 2.5rem, 2.5rem, colors.$color-black);
                background-color: var(--color-grey-100);
            }
          }
        }

        &-phone {

          &:after {
              @include background-svg.params(svg.$svg-phone, transparent, center, center, no-repeat, 2.5rem, 2.5rem, colors.$color-grey-100);
              background-color: var(--color-primary-1);

          }

          &:hover {
            &:after {
                @include background-svg.params(svg.$svg-phone, transparent, center, center, no-repeat, 2.5rem, 2.5rem, colors.$color-black);
                background-color: var(--color-grey-100);
            }
          }
        }
        &-linkedin {

          &:after {
              @include background-svg.params(svg.$svg-linkedin, transparent, center, center, no-repeat, 2.5rem, 2.5rem, colors.$color-grey-100);
              background-color: var(--color-primary-1);
            }

          &:hover {
            &:after {
                @include background-svg.params(svg.$svg-linkedin, transparent, center, center, no-repeat, 2.5rem, 2.5rem, colors.$color-black);
                background-color: var(--color-grey-100);
            }
          }
        }

      }
    }

  }
}