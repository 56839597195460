@use "../mixins/mq";

.teaser-pattern {
    margin-top: 2.5rem;
    @include mq.mq($from: large) {
        margin-top: 4rem;
    }
}
.teaser {
    --teaser-icon-width: 3rem;
    --teaser-icon-margin: 1.5rem;
    --outline-color: var(--color-black);


    /* base scheme is dark */
    --teaser-bg-color: var(--color-secondary-1);
    --teaser-color: var(--color-white);
    --teaser-header-font-size:  var(--font-size-h3);
    
    display: grid;
    position: relative;
    text-decoration: none;
    color: var(--teaser-color);
    border-radius: var(--border-radius);
    min-height: var(--teaser-min-height,30rem);
    overflow: hidden;

    @include mq.mq($from: large) {
        --teaser-padding: 5rem;
        --teaser-icon-width: 5rem;
        --teaser-icon-margin: 2rem;
        --teaser-min-height: 38rem;
    }


    &--has-cover {
        --teaser-bg-color: var(--color-black-transparent);
    }

    &__cover {
        position: absolute;
        inset: 0;
        --img-height: 100%;
        --img-width: 100%;
    }

    &__content {
        isolation: isolate;
        padding: var(--teaser-padding, 2.5rem);
        background-color: var(--teaser-bg-color);
    }

    &__icon {
        position: absolute;
        top: 0;
        right: 0;
        --icon-width: var(--teaser-icon-width);
        margin: var(--teaser-icon-margin);

    }

    &__info {
        margin: 0 2.2rem 1.5rem 0;

    }

    &__header {
        margin: 0 0 1.5rem;
        font-size: var(--teaser-header-font-size);
        text-wrap: balance;
    }

    &__text {
        margin: 0;
        font-size: var(--teaser-text-font-size);
    }

    &__chips {
        display: inline-flex;
        gap: 0.5rem;
        flex-wrap: wrap;
    }

    &--fade-in {
        animation: fadeInZoom .5s ease-in-out;
    }

}


.teaser-text-box{
    text-align: center;
    color: var(--color-white);
    position: absolute;
    left: 0;
    right: 0;
}

/* custom code for the grid */

.teaser-pattern-dark {
    > :not(.teaser--has-cover):nth-child(odd) {
        //teaser
        --teaser-color: var(--color-black);
        --teaser-bg-color: var(--color-white);

        //chips
        --chips-color: var(--color-white);
        --chips-bg-color: var(--chips-dark-bg-color);
    }
}

.teaser-pattern {
    > :not(.teaser--has-cover):nth-child(even) {
        //teaser

        --teaser-bg-color: var(--color-black);
    }
}

.teasergrid {
    min-height: 30rem;
    margin: 5rem 0;

    @include mq.mq($from: medium) {

    

    > :first-child {
        
            --teaser-header-font-size: var(--font-size-h1);
            --teaser-text-font-size: var(--font-size-h4);
            --teaser-padding: 8rem;

            grid-row-end: 2 span;
            grid-column-end: 2 span;
        
        }
    }

    

}


