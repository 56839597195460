@use "../mixins/mq";


.c-facts {
  padding-left: var(--spacing-25);
  padding-right: var(--spacing-25);

  &__title {
    color: var(--color-grey-100);
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 0 18px;
    font-weight: bold;
    max-width: 50rem;
    @include mq.mq($from: large) {
      max-width: 100%;
    }
  }
  /*
  &__title {
    color: var(--color-grey-100);

    text-transform: uppercase;

    font-weight: bold;

    z-index: 0;

    font-size: 6rem;
    line-height: 7rem;
    @include mq.mq($from: large) {
      white-space: nowrap;
      font-size: 20rem;
      line-height: 24rem;
      left: var(--spacing-50);
      bottom: -4.3rem;
      position: absolute;
      right: 0;
      overflow: hidden;
    }
  }


  &__title {
    color: var(--color-grey-100);

    


    @include mq.mq($from: large) {
      white-space: nowrap;
      font-size: 20rem;
      line-height: 24rem;
      left: var(--spacing-50);
      bottom: -4.3rem;
      position: absolute;
      right: 0;
      overflow: hidden;
    }
  }
    */

  &__container {
    border-radius: var(--border-radius);
    background-color: var(--color-white);
    padding-top: var(--spacing-50);
    position: relative;

    @include mq.mq($from: large) {
      padding-top: var(--spacing-100);
      padding-bottom: 20rem;
    }
  }
  &-item {
    padding-bottom: 0;
    @include mq.mq($from: medium) {
      grid-column-start: initial;
    }
    @include mq.mq($from: large) {
      padding-bottom: var(--spacing-25);
    }
    z-index: 1;
    &__number {

      font-size: 2.7rem;
      line-height: 3.7rem;
      margin-bottom: var(--spacing-5);
      font-weight: var(--font-weight-light);

      @include mq.mq($from: large) {
        font-size: var(--font-size-h1);
        margin-bottom: var(--spacing-15);
        line-height: var(--line-height-h1);
      }
    }
    &__title {

      color: var(--color-black);
      font-weight: var(--font-weight-light);
      margin-bottom: var(--spacing-50);
      font-size: 2rem;
      line-height: 3rem;
      @include mq.mq($from: large) {
        font-size: 3.2rem;
        line-height: 4.2rem;
        margin-bottom: 0;
      }
    }
  }
}