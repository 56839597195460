@use "../mixins/mq";
@use "../mixins/col";
@use "../_base/typography";


.footer {
  color: var(--color-white);

  --link-text-decoration: none;
  --link-text-decoration-hover: underline;

}

.footer__bottom {
  padding-top: var(--spacing-50);
  padding-bottom: 1.5rem;
  font-size: var(--font-size-h5);
  background-color: var(--color-black);
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);

  .row {
    @include mq.mq($from: large) {
      align-items: center;
      justify-content: space-between;
    }
  }
}

  .footer__row {
    display: grid;
    gap: var(--spacing-50);
    @include mq.mq($from: large) {
      grid-template-columns: 5fr 7fr;
      gap: 0;
    }
  }

  .footer__col-right {
    display: grid;
    gap: 2.5rem;
    --paragraph-spacing-top: 1.5rem;
    font-size: 1.6rem;
    line-height: 1.5625;

    @include mq.mq($from: medium) {
      grid-template-columns: repeat(2, 1fr);
      gap: 5rem 8rem;
    }
    @include mq.mq($from: medium) {
      grid-template-columns: repeat(3, 1fr);

      .footer__block:nth-child(3){
        grid-column-start: 1;

      }

    }




  }

.footer__bottom-left {

  margin-bottom: auto;
  display: inline-flex;

  .footer__logo {
    svg {
      width: 13.5rem;
      @include mq.mq($from: large) {
        width: 22.5rem;
      }
    }
  }

}
.footer__copyright {
  color: var(--color-secondary-1);
  font-size: 1.4rem;
  line-height: 2.5rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  display: inline-flex;
}
.footer__navigation {
  display: flex;
  flex-wrap: wrap;
  gap: 1.25rem 2.5rem;

}

.nav--footer-nav {

  .nav__wrap {
    display: flex;
    flex-direction: row;
    gap: 2.5rem;
    flex-wrap: wrap;
  
    .nav__item {
      text-transform: uppercase;
      color: var(--color-secondary-1);
      font-size: 1.4rem;
      line-height: 2.5rem;
      letter-spacing: 1px;
      text-transform: uppercase;
      margin: 0;
    }
  }
}

.footer__icon {
  background-image: url("/web/assets/images/footer-oval.png");
  width: 5rem;
  height: 5rem;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  .icon {
    color: #D0D0DD;
    --icon-width: 2rem;
    --icon-height: 2rem;
  }
}
