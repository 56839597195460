.spinner {
    --mask: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' width='50' height='50' viewBox='0 0 50 50' xml:space='preserve'%3E%3Ccircle fill='none' stroke='currentColor' stroke-width='7' cx='25' cy='25' r='14'/%3E%3C/svg%3E");
    display: block;
    background-image: conic-gradient(transparent 90deg, var(--spinner-color, white));
    -webkit-mask: var(--mask) 0/100% 100%;
    mask: var(--mask) 0/100% 100%;
    transition: filter 5s;
    will-change: transform;
    animation: spin 1s linear infinite;
    animation-play-state: paused;


  
    .is-loading &,
    [aria-busy] &,
    &--spinning {
        animation-play-state: running;

    }
}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}