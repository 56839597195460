@use "sass:color";
@use "../_config/colors";
@use "../mixins/mq";

svg,
img {
	max-width: 100%;
	width: var(--img-width,auto);
	height: var(--img-height,auto);
	object-fit: var(--img-object-fit,cover);
	display: block;
}

img {
	object-position: var(--focalpoint);
}

video {
	width: 100%;
}

.figure {
	position: relative;
	overflow: hidden;

	&:after {
		position: absolute;
		top: 0;
		display: block;
		width: 100%;
		height: 100%;
		content: '';
	}

	picture,
	img,
	video {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}

	img {
		transition: transform var(--animationDuration) var(--animationEase);
		image-rendering: -webkit-optimize-contrast;
		backface-visibility: hidden;
	}

	&-three {
		height: 0;
		padding-bottom: 76%;
	}

	&-two {
		height: 0;
		padding-bottom: 72.25%;
		padding-bottom: 100%;
	}

	

}
.figure-logo {
	display: flex;
	justify-content: center;
}
.figure-slider {
	height: 0;
	padding-bottom: 100%;

	@include mq.mq($from: medium) {
		padding-bottom: 77.56%;
	}
}

video::-webkit-media-controls,
video::video::-webkit-media-controls-enclosure,
video::-webkit-media-controls-overlay-play-button {
	display: none !important;
	-webkit-appearance: none !important;

}
