@use "../mixins/background-svg";
@use "../_config/colors";
@use "../mixins/mq";
@use "../_base/svg";




:root {
  --container-wdith: 160rem;

  --globalFontSize: 1.7rem;
  --globalLineHeight: 1.588235294;

  --color-black: #{colors.$color-black};
  --color-grey-400:  #{colors.$color-grey-400};
  --color-grey-300:  #{colors.$color-grey-300};
  --color-grey-200:  #{colors.$color-grey-200};
  --color-grey-100:  #{colors.$color-grey-100};
  --color-white:  #{colors.$color-white};
  --color-primary-1:  #{colors.$color-primary-1};
  --color-primary-2:  #{colors.$color-primary-2};
  --color-secondary-1:  #{colors.$color-secondary-1};
  --color-secondary-2:  #{colors.$color-secondary-2};
  --color-error:  #{colors.$color-error};
  --color-success:  #{colors.$color-success};
  --color-warning:  #{colors.$color-warning};
  --color-black-transparent:  #{colors.$color-black-transparent};
  --color-error-transparent:  #{colors.$color-error-transparent};
  --color-primary-1-transparent:  #{colors.$color-primary-1-transparent};
  --color-meta-nav: #{colors.$color-meta-nav};

  --scroll-padding: 6.5rem;


  --font-size-900: 2.7rem;
  --font-size-800: 2.4rem;
  --font-size-700: 2.0rem;
  --font-size-600: 1.9rem;
  --font-size-400: 1.7rem;
  --font-size-300: 1.8rem;
  --font-size-200: 1.6rem;
  --font-size-100: 1.4rem;


  --paragraph-spacing-top: 2rem;

  --font-size-label: 1.7rem;
  --font-size-label-small: 1.7rem;

  --font-size-h1: var(--font-size-900);
  --font-size-h2: var(--font-size-800);
  --font-size-h3: var(--font-size-700);
  --font-size-h4: var(--font-size-600);
  --font-size-h5: var(--font-size-300);
  --font-size-h6: var(--font-size-200);

  --font-weight-light: 300;
  --font-weight-regular: 500;
  --font-weight-bold: 700;
  --font-weight-h1: 300;
  --font-weight-h2: 300;
  --font-weight-h3: 300;
  --font-weight-h5: 700;

  --line-height-h1: 1.31;
  --line-height-h2: 1.37;
  --line-height-h3: 1.53;
  --line-height-h4: 1.53;
  --line-height-h5: 1.47;
  --line-height-h6: 1.6;

  --letter-spacing-h1: 0;
  --letter-spacing-h2: 0;
  --letter-spacing-h3: 0.65;
  --letter-spacing-h4: 0.65;
  --letter-spacing-h5: 0.7;
  --letter-spacing-h6: 0;

  --spacing-h1: 1.2em;
  --spacing-h2: 1em;
  --spacing-h3: 0.9em;
  --spacing-h4: 0.5em;
  --spacing-h5: 1em;
  --spacing-h6: 1em;


  --spacing-200: 20rem;
  --spacing-100: 10rem;
  --spacing-80: 8rem;
  --spacing-50: 5rem;
  --spacing-25: 2.5rem;
  --spacing-15: 1.5rem;
  --spacing-5: 0.5rem;

  --link-color: inherit;
  --link-color-hover: var(--color-primary-1);
  --link-text-decoration: underline;
  //--link-text-decoration-hover: underline;

  --wrapper-gap: 1.7rem;

  --font-size-button: 1.6rem;

  --button-primary-min-height: 4rem;
  --button-primary-min-width: 20rem;

  --bodyFontFamily: europa, sans-serif;
  --body-color: var(--color-black);
  --body-bg-color: var(--color-meta-nav);
 
  --mobile-menu-max-width: 70rem;

  --component-spacing: 10rem;
  --text-block-margin-bottom: 2.5rem;

  --spinner-color: var(--color-white);

  @include background-svg.to-custom-property(svg.$svg-bg-x-full, colors.$color-grey-100,'--body-bg-grey');
	@include background-svg.to-custom-property(svg.$svg-bg-x, colors.$color-black,'--body-bg-bright');
	@include background-svg.to-custom-property(svg.$svg-bg-x, colors.$color-secondary-1,'--body-bg-dark');
  --body-bg: var(--body-bg-bright);
  --body-bg-color: var(--color-grey-100);
  //--layout-bg-size: 45rem auto;
  --body-bg-size: 115% auto;
  --body-bg-position: right 1.2rem top 1.5rem;

  --headerHeightSmall: 6rem;
  --headerHeight: 13rem;

  --hamburger-color: var(--body-color);

  --header-bg-color: transparent;
  --header-border-color: var(--color-white);

  --header-meta-height: 1rem;
  --header-menu-height: 5rem;

  --body-padding-top: var(--header-meta-height);

  --hamburgerLineHeight: 1px;
  --hamburgerWidth: 25px;
  --hamburgerSpacing: 12px;

  --border-radius: 2rem;
  --header-border-radius: 3rem;



  --grid-gap: .5rem;
  --grid-columns: 1;

  --animationDuration: 0.25s;
  --animationEase: cubic-bezier(.25,.46,.45,.75);

  --chips-font-size: 1.6rem;
  --chips-letter-spacing: .1rem;
  --chips-bg-color: var(--color-white);
  --chips-color: var(--color-black);
  --chips-large-font-size: 1.8rem;

  --chips-dark-bg-color: var(--color-black);
  --chips-dark-color: var(--color-white);

  --chips-active-bg-color: var(--color-primary-1);
  --chips-active-color: var(--color-white);

  --filter-gap: 1.5rem 2.5rem;

  // old
  --select-bg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='41' height='26' viewBox='0 0 41 26'%3E%3Cpath d='M40.5 5.257 35.8.5 20.5 15.952 5.2.5.5 5.257l20 20.243z'/%3E%3C/svg%3E");

  --form-spacing: 2.5rem;
  --field-border-radius: 1rem;
  --field-border-color: transparent;
  --field-background-bg: var(--color-white);
  --field-control-size: 3.5rem;

  --field-group-margin-bottom: var(--form-spacing);


  //form new
  --form-row-gap: 2.5rem;
  --color-disabled: var(--color-grey-200);

  --label-font-size: 1.6rem;
  --label-line-height: 1.5625;
  --label-letter-spacing: 0.05rem;
  --label-padding: 0;
  --label-color: currentColor;
  --label-text-transform: uppercase;

  --label-legend-padding: 0;
  --label-legend-font-size: var(--label-font-size);
  --label-legend-line-height: var(--label-line-height);
  --label-legend-letter-spacing: var(--label-letter-spacing);
  --label-legend-font-weight: 600;

  --field-color: currentColor;
  --field-margin-bottom: 2.5rem;
  --field-error-font-size: 1.4rem;
  --field-error-line-height: 1.5;
  --field-error-letter-spacing: .01rem;
  --field-error-color: var(--color-error);
  --field-error-margin: .8rem 0 0;

  --input-font-size: 1.7rem;
  --input-line-height: 1.58823529;
  --input-padding-inline: .9rem;
  --input-padding-block: 1.6rem;
  --input-border-width: .1rem;
  --input-border-radius: 1rem;
  --input-background-color: var(--color-white);
  --input-border-color: var(--input-background-color);
  --input-transition: color var(--animation-duration) var(--animation-ease), background-color var(--animation-duration) var(--animation-ease), border-color var(--animation-duration) var(--animation-ease);
  --input-focus-border-color: var(--color-black);

  @include background-svg.to-custom-property(svg.$svg-chevron-down, colors.$color-black, --input-select-arrow-black);
  @include background-svg.to-custom-property(svg.$svg-chevron-down, colors.$color-error, --input-select-arrow-error);
  @include background-svg.to-custom-property(svg.$svg-chevron-down, colors.$color-white, --input-select-arrow-white);

  --input-select-padding-right: 4rem;
  --input-select-arrow-bg-position: right 1.5rem center;
  --input-select-arrow-bg: var(--input-select-arrow-black);

  --check-size: 2.5rem;
  --check-color: var(--color-primary-1);
  --check-border-width: .2rem;
  --check-bg-color: transparent;
  --check-transition: background-color var(--animation-duration) var(--animation-ease), border-color var(--animation-duration) var(--animation-ease), color var(--animation-duration) var(--animation-ease);
  --label-option-padding-left: calc(var(--check-size) + 1rem);
  --label-option-font-size: 1.6rem;
  //--check-bg-color: var(--color-primary-900);
  --check-checked-color: var(--color-primary-1);
  --check-hover-color: var(--color-primary-1);
  --check-checked-bg-color: currentColor;

  @include background-svg.to-custom-property(svg.$svg-check, colors.$color-white, --check-bg-image);
  @include background-svg.to-custom-property(svg.$svg-radio, colors.$color-white, --check-radio-bg-image);


  @include mq.mq($from: large) {

    --button-primary-min-height: 5rem;
    --globalFontSize: 1.8rem;
    --globalLineHeight: 1.555555556;
    --font-size-label: 1.8rem;
    --paragraph-spacing-top: 2.5rem;
    --scroll-padding: 14rem;

    --font-size-900: 8rem;
    --font-size-800: 5rem;
    --font-size-700: 3.2rem;
    --font-size-600: 2.5rem;
    --font-size-400: 1.8rem;
    --font-size-300: 2.3rem;
    --font-size-200: 1.6rem;
    --font-size-100: 1.4rem;

    /*
    --font-size-h1: 8rem;
    --font-size-h2: 5rem;
    --font-size-h3: 3.2rem;
    --font-size-h4: 2.5rem;
    --font-size-h5: 2.3rem;
    --font-size-h6: 1.6rem;
*/

    --line-height-h1: 1.25;
    --line-height-h2: 1.3;
    --line-height-h3: 1.3125;
    --line-height-h4: 1.4;
    --line-height-h5: 1.52173913;
    --line-height-h6: 1.5625;

    --letter-spacing-h1: 0;
    --letter-spacing-h2: 0;
    --letter-spacing-h3: 0;
    --letter-spacing-h4: 0;
    --letter-spacing-h5: 1.53;
    --letter-spacing-h6: 0.5;

    --spacing-h1: 1.2em;
    --spacing-h2: 1em;
    --spacing-h3: 0.9em;
    --spacing-h4: 0.7em;
    --spacing-h5: 1.2em;
    --spacing-h6: 1.1em;

    --wrapper-gap: 2.4rem;

    --form-spacing: 5rem;

    --border-radius: 3rem;

    --chips-large-font-size: 2.3rem;
    --chips-large-padding: 2.2rem;
    --chips-large-letter-spacing: .15rem;

    --filter-gap: 2.5rem;

    --body-bg-size: 128rem auto;
    --body-bg-position: -10rem -2.8rem;
  
    --header-meta-height: 4rem;
    --header-menu-height: 9.4rem;

    --component-spacing: 20rem;
    --text-block-margin-bottom: 5rem;


    --form-row-gap: 5rem;
  }
}

$bodyFontFamily: "TT Norms", Helvetica, Arial, sans-serif;

@media (prefers-reduced-motion: reduce) {
  :root {
    --animationDuration: 0;
    --animationEase: none;
  }
}
