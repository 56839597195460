@use "../mixins/mq";
@use "../_base/svg";
@use "../_config/colors";
@use "../mixins/background-svg";
.contactButton {
  --icon-width: 2rem;
  --icon-height: 2rem;

  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  float: right;

  @include mq.mq($from: large) {
    --icon-width: 3.2rem;
    --icon-height: 3.2rem;
  }

  &__action {
    background-color: var(--color-white);
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
    width: 5rem;
    height: 5rem;
    display: flex;
    align-items: center;
    align-content: center;
    padding: 0.5rem;
    box-shadow: 0 0 3rem 0 rgba(35,35,35,0.45);
    cursor: pointer;
    @include mq.mq($from: large) {
      width: 8rem;
      height: 8rem;
      padding: 0.8rem;
    }

    .contactButton--show & {
      display: none;
    }

    > div {
      width: 100%;
      height: 100%;
      background-image: url("/web/assets/images/footer-oval.png");

      background-size: contain;
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        max-width: 1.16rem;
        @include mq.mq($from: large) {
          max-width: 1.9rem;
        }
      }
    }


  }

  &__infos {
    width: 60rem;
    min-height: 20rem;
    background-color: var(--color-white);
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
    padding: var(--spacing-15);

    gap: var(--spacing-15);
    position: relative;
    display: none;
    box-shadow: 0 0 3rem 0 rgba(46,45,51,0.2);
    max-width: 28.5rem;
    flex-direction: column;
    @include mq.mq($from: large) {
      max-width: 60rem;
      flex-direction: row;
      gap: 3rem;
      padding: var(--spacing-15) var(--spacing-50) var(--spacing-15) var(--spacing-15);
    }
    .contactButton--show & {
      display: flex;
    }

  }
  &__close {

    @include background-svg.params(svg.$svg-close, transparent, center, center, no-repeat, 1.5rem, 1.5rem, colors.$color-black);
    position: absolute;
    top: var(--spacing-15);
    right: var(--spacing-15);
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
    z-index: 1;
  }
  &__image {
    .figure-contact {
      position: initial;
      picture {
        position: initial;
        width: 13.5rem;
        height: 15rem;
        display: block;
        @include mq.mq($from: large) {
          width: 15rem;
          height: 17rem;

        }
        .img-contact {
          width: 100%;
          height: 100%;
          position: initial;
          border-radius: 1rem;
        }
      }

    }

  }
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 1;
    gap: var(--spacing-15);
    @include mq.mq($from: large) {
      gap: 0;
    }
  }
  &__text {
    font-size: 1.9rem;
    color: var(--color-black);
    line-height: 1.52631579;



    @include mq.mq($from: large) {
      font-size: var(--font-size-h4);
      margin-top: -0.5rem;
      line-height: var(--line-height-h4);
      flex-grow: 1;
    }
  }
  &__name {
    font-weight: var(--font-weight-bold);
    font-size: 1.7rem;
    line-height: 1.58823529;
    color: var(--color-secondary-1);
    letter-spacing: 0.05rem;
    text-transform: uppercase;
    @include mq.mq($from: large) {
      font-size: 1.8rem;
      line-height: 1.55555556;

    }
  }
  &__contactdetails {
    display: flex;
    gap: var(--spacing-15);

    flex-direction: column;
    @include mq.mq($from: large) {
      flex-direction: row;
      gap: 4rem;
      margin-bottom: -0.45rem;
    }
  }
  &__link {
    > a {
      text-decoration: none;
      color: var(--color-black);
      letter-spacing: 0.05rem;
      font-size: 1.6rem;
      line-height: 2.5rem;
      font-weight: var(--font-weight-bold);
      @include mq.mq($from: large) {


      }

    }
  }
}