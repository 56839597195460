.form {
    &__row {
        display: flex;
        gap: var(--form-row-gap,0);
        flex-wrap: wrap;
    }

    &__nested-field {
        padding: 1rem;
        background: rgba(127,127,127,.3);
        margin: 1.6rem -1rem;
    }

    &__actions {
        display: flex;
        gap: 1.6rem;
        justify-content: space-between;
    }
}