@use "../mixins/mq";
@use "../_base/typography";

.nav--main-nav {
  flex-grow: 1;
  justify-content: flex-end;

  .nav {
    &__item--parent {
      position: relative;
      //max-height: 7rem;
      width: 100%;
      /*margin: 0 2rem;*/
      //transition: max-height 0.5s var(--animationEase);
      //overflow: hidden;
      border-bottom: 1px solid var(--color-primary-1-transparent);

      @include mq.mq($from: large) {
        width: auto;
        margin-left: 3.2rem;
        margin-right: 0;
        //overflow: visible;
        border-bottom: 0;
      }
    }

    &__item--child {
  


      @include mq.mq($from: large) {
        color: var(--color-white);
        margin: 1rem 0;
        margin-bottom: 0;
      }

      &:hover {
        .nav__link {
          border-bottom: 0.1rem solid var(--color-primary-1);
        }
      }
    }

    &__link {
      @extend .font-weight-700;
      border-bottom: 1px solid var(--color-white);
      &--parent {
        display: flex;

        padding: 1rem 4rem 1rem 0;
        align-items: center;
        font-size: 1.8rem;
        line-height: 2.8rem;
        letter-spacing: 0.5px;
        color: inherit;
        font-weight: bold;
        border-bottom-color: transparent;
   
        @include mq.mq($from: large) {
         
          /*height: 7rem;*/
          padding: 0;
        }

        .not-touch & {
          &:hover {
            color: var(--color-primary-1);
          }
        }

        @include mq.mq($from: large) {
          &.is-active {
            border-bottom: 0.1rem solid var(--color-primary-1);
          }
        }
      
      }

      &--child {
        display: block;
        color: inherit;
        min-height: var(--spacing-50);
        padding-left: 1.5rem;
        display: grid;
        align-items: center;
    
        @include mq.mq($from: large) {
          min-height: auto;
          padding-left: 0;
          border-bottom: 0.1rem solid transparent;
          &.is-active {
            border-bottom: 0.1rem solid var(--color-primary-1);
            color: var(--color-white);
          }
        }
      
      }
    }
  }
}

.header__nav-main {

  background-color: var(--header-bg-color);
  border-radius: var(--layout-border-radius);
  /*	border-top-left-radius: 1.5rem;
      border-top-right-radius: 1.5rem;*/
  width: 100%;
  height: 9.4rem;
  padding: 0 5rem;
  display: flex;
  align-items: center;

  border: 0.1rem solid transparent;

  &-container {
    button {
      display: none;
    }

    @include mq.mq($until: large) {
      display: flex;
      justify-content: space-between;
      width: 100%;
      button {
        display: block;
        margin: 0;


        border-color: var(--color-black);

        .hamburger__line {
          background-color: var(--color-black);
        }
      }
    }
  }

}
