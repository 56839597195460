@use "../mixins/mq";

.breadcrumb__wrapper {
  display: flex;
  visibility: visible;
  margin-bottom: var(--spacing-15);
}

.breadcrumb {
  display: flex;
  list-style: none;
  margin-top: 0;
  margin-left: 0;
  justify-content: center;

  &--left {
    justify-content: flex-start;
  }
}

.breadcrumb__wrapper {
  position: relative;
  z-index: 2;
  max-width: 100vw;
  white-space: nowrap;
  overflow: auto;
  height: 2rem;
  overflow-y: hidden;
  padding: 0;
  -webkit-overflow-scrolling: touch;
}

.breadcrumb--child {
  position: fixed;
  left: -22px;
  top: 100%;
  display: none;
  //display: flex;
  flex-direction: column;
  width: auto;
  max-width: 100%;
  /*min-width: calc(100% + 25px);*/
  padding: 1rem 0;
  background-color: var(--color-grey-300);
  word-break: keep-all;
  white-space: nowrap;
}

.breadcrumb__item {
  //position: relative;
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  line-height: 2rem;
  overflow: visible;
  padding-left: 0;
  letter-spacing: 0.1rem;
  font-weight: var(--font-weight-bold);

  &:not(:last-child) {
    &:after {
      content: '/';
      font-weight: var(--font-weight-bold);
      margin-left: 1.4rem;
    }
  }


  .icon-breadcrumb {
    width: 2rem;
    height: 2rem;
  }
  margin-right: 1rem;


  &:not(:first-child) {
    margin-top: 0;
  }

  .icon-chevron-right {
    margin: 0 1rem;
  }
}

.breadcrumb__trigger {
  height: 2rem;
  margin-left: var(--spacingSmall);
  color: var(--color-grey-300);

  .breadcrumb--left & {
    color: var(--color-white);
  }

  .icon {
    transform-origin: center center;
  }
}

.breadcrumb__item-title {
  display: inline-flex;
  align-items: center;
  height: 2rem;

  text-decoration: none;


}

a.breadcrumb__item-title {
  .not-touch & {
    &:hover {
      text-decoration: underline;
    }
  }
}

.breadcrumb__item--home {
  margin-left: 0;
}

.breadcrumb__link--home {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: inherit;
}

.breadcrumb__item--last {
  padding-right: var(--spacingRegular);
  font-weight: 900;
  cursor: default;
}

.breadcrumb__item--opened {
  .breadcrumb--child {
    display: flex;
  }

  .breadcrumb__trigger {
    .icon {
      transform: rotate(180deg);
    }
  }
}

.dark {
  .breadcrumb__item-title {

  }
  .breadcrumb__link--home {


  }

  .breadcrumb__item--last {

 

  }
}