@use "../mixins/mq";

.component {

	&--spacing-bottom {
		margin-bottom: var(--component-spacing);
	}

	&--spacing-top {
		margin-top: 2.5rem;
	@include mq.mq($from: large) {
		margin-top: 5rem;
	}
	}
}
