@use "../mixins/mq";
@use "../_base/typography";

.nav--meta-nav {
	margin-top: 2rem;
	@extend .font-size-h5;

	padding: 0 5rem;

	color: var(--color-grey-100);


	height: 4rem;

	@include mq.mq($from: large) {
		margin: 0;
		padding: 0;
	}

	.nav__item--parent {
		width: 100%;
		margin: 0rem 2rem;

		@include mq.mq($from: large) {
			width: auto;
			margin-left: 3rem;
			margin-right: 0;
		}
	}

	.nav__link {
		.not-touch & {
			&:hover {
				color: var(--color-primary-1);
			}
		}

		color: var(--color-grey-100);
		font-size: 1.6rem;
		letter-spacing: 0.5px;
		line-height: 2.5rem;
		font-weight: bold;
		&.is-active {
			border-bottom: 0.1rem solid var(--color-primary-1);
		}

	}
}
