@use "../mixins/mq";

.icon {
	position: relative;
	display: var(--icon-display, inline-flex);
	font-style: normal;
	color: var(--icon-color, currentColor);
	width: var(--icon-width, auto);
	height: var(--icon-height, auto);
}

$icons: (
		('chevron-down', 22, 22, 22, 22, 22, 22),
		('chevron-right', 6, 11, 6, 11, 6, 11),
		('home', 30, 27, 30, 27, 31, 36),
		('play', 20, 20, 20, 20, 36, 40),
		('user', 30, 30 ),
		('paycheck', 30, 30),
);

@each $name,
	$widthSmall,
	$heightSmall,
	$widthMedium,
	$heightMedium,
	$widthLarge,
	$heightLarge in $icons {
	.icon-#{$name} {
		--icon-width: #{calc($widthSmall / 10)}rem;
		--icon-height: #{calc($heightSmall / 10)}rem;

		@if $widthMedium and $widthMedium != 0 {
			@include mq.mq($from: medium) {
				--icon-width: #{calc($widthMedium / 10)}rem;
				--icon-height: #{calc($heightMedium  / 10)}rem;
			}
		}
		@if $widthLarge and $widthLarge !=  0  {
			@include mq.mq($from: large) {
				--icon-width: #{calc($widthLarge / 10)}rem;
				--icon-height: #{calc($heightLarge / 10)}rem;
			}
		}
	}
}
