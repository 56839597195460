@use "../mixins/mq";

.hero {
    &--polled-up {
        margin-top: -2.5rem;
 
    }
    margin-bottom: 5rem;

    &__cover {
        background-color: var(--color-black);
        border-radius: 0 0 var(--border-radius) var(--border-radius);
        color: var(--color-white);
        min-height: 40rem;
        padding: 10rem 0 12rem;
        position: relative;
    }

    &__header {
        text-wrap: balance;
        max-width: 80rem;
    }

    &__author {
        border-radius: 1rem;
        overflow: hidden;
    }

    &__media {
        position: absolute;
        inset: 0;
        --img-height: 100%;
        --img-width: 100%;
        opacity: .2;
    }

    &__chips {
        display: flex;
        gap: 1.5rem;
        flex-wrap: wrap;
        margin-bottom: 1.5rem;
    }

    &__overlay {
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        gap: 1.5rem;
        margin-top: -10rem;
    }

    &__box {
        --paragraph-spacing-top: 1.7rem;
        border-radius: 1rem;
        padding: 1.5rem;
        background-color: var(--color-white);
        width: 53.5rem;
        max-width: 100%;

        &--secondary {
            background-color: var(--color-secondary-1);
            color: var(--color-white);
            width: 28rem;
        }

        &--inline {
            display: flex;
            gap: 1.5rem;
        }
    }

    &__boxbody {
        margin-top: 1rem;
    }

    @include mq.mq($from: medium) {
        &__overlay {
            flex-direction: row;
        }

        &__boxbody {
            margin-top: 3.5rem;
        }
    }

    @include mq.mq($from: large) {
        margin-bottom: 10rem;
        &__cover {
            min-height: 60rem;
        }

        &--polled-up {
            margin-top: -4.7rem;
   
        }
    }
}