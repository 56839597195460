@use "../mixins/mq";
@use "../atoms/grid";
// TODO lineheights on mobile
.c-list {
  &__box {
    background-color: var(--color-secondary-1);
    color: var(--color-grey-100);
    border-radius: var(--border-radius);
    padding: var(--spacing-50) var(--spacing-15);
    margin-top: 0.5rem;
    @include mq.mq($from: large) {
      padding: var(--spacing-80) var(--wrapper-gap);
    }
  }
  &__supertitle {
    text-transform: uppercase;
    font-size: 1.8rem;

    letter-spacing: 1.53px;
    font-weight: var(--font-weight-bold);
    margin-bottom: 2rem;
    @include mq.mq($from: large) {
      font-size: 2.3rem;
      line-height: 3.5rem;
    }
  }
  &__title {
    font-size: 2.4rem;
    font-weight: var(--font-weight-light);

    @include mq.mq($from: large) {
      font-size: 5rem;
      line-height: 6.5rem;
    }
  }
  &__text {
    font-size: 1.9rem;
    line-height: 3.5rem;
    font-weight: var(--font-weight-regular);
    @include mq.mq($from: large) {
      font-size: 2.5rem;
    }
    > :first-child {
      margin-top: 0;

    }
    > ul:first-child {


      @include mq.mq($from: large) {
        margin-top: -2rem;
      }

    }
    > ul:last-child {
      margin-bottom: 0;
    }

    ul {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      column-gap: 5rem;
      margin: var(--spacing-50) 0;


      @include mq.mq($from: large) {
        grid-template-columns: repeat(2, 1fr);
      }

      li {
        font-weight: var(--font-weight-bold);
        font-size: 1.7rem;
        line-height: 2.8rem;
        letter-spacing: 0.5px;
        border-bottom: 0.1rem solid var(--color-grey-100);
        padding: 2rem 0;
        margin: 0;
        @include mq.mq($from: large) {
          font-size: 1.8rem;
          line-height: 2.8rem;
          padding: 2rem 0;
        }
        &::marker {
          display: none;
          color: transparent;
        }
      }
    }
  }
}
