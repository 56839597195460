@use "../mixins/mq";
@use "../mixins/background-svg";
@use "../_config/colors";
@use "../_base/svg";


[data-conditionally-hidden],
[data-fui-page-hidden],
[data-conditionally-hidden] {
    display: none;
}

//group for label and input
.field {
    flex: 1;
    margin-bottom: var(--field-margin-bottom);
    color: var(--field-color,currentColor);
    &__errors,
    &__error {
        list-style: none;
        font-size: var(--field-error-font-size);
        line-height: var(--field-error-line-height);
        letter-spacing: var(--field-error-letter-spacing);
        color: var(--field-error-color, currentColor);
        margin: var(--field-error-margin);
        font-weight: 500;
    }
    &__option-group { 
        display: grid;
        gap: 1.5rem;
    }

    &__option,
    &__group {
        position: relative;
    }

	&__instructions {
		font-size: var(--field-instructions-font-size, 1.2rem);
	}


    &--no-space {
        margin-bottom: 0;
    }

    &--hidden {
        display: none;
    }

    &__append {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 7rem;
        right: 0;
        display: grid;
        align-items: center;
        border-radius: var(--input-border-radius);
    }

    &--hidden {
        display: none;
    }

    &__icon {
        position: absolute;
        top: 0;
        left: 1.5rem;
        align-items: center;
        justify-content: center;
        bottom: 0;
        display: grid;
        width: 1.6rem;

        ~ .input {
            padding-left: 4.5rem;
        }
    }
}


.input {
    // Resets
    font-family: inherit;
    font-size: var(--input-font-size);
    line-height: var(--input-line-height);
    margin: 0;
    appearance: none;
    color: var(--input-color, currentColor);
    width: 100%;
    display: block;
    padding-inline: var(--input-padding-inline);
    padding-block:  var(--input-padding-block);
    border: 1px solid var(--input-border-color);
    border-width: var(--input-border-width,1rem);
    border-radius: var(--input-border-radius);
    background-color: var(--input-background-color);
    background-clip: padding-box;
    transition: var(--input-transition);

    &:focus-visible  {
        --input-border-color: var(--input-focus-border-color);
    }

    &[disabled] {
        --input-border-color: var(--color-disabled);
        --input-background-color: var(--color-disabled);
    }


    &--file {
        &::file-selector-button {
            color: var(--color-white);
            padding: 0 1rem;
            margin: calc( var(--input-padding-block) * -1) 1.5rem calc( var(--input-padding-block) * -1) calc( var(--input-padding-inline) * -1);
            isolation: isolate;
            border: 0;
            appearance: none;
            height: 6.1rem;
            text-align: center;
            font-size: var(--input-font-size);
            background-color: var(--color-primary-1);
        }
    }

    &--center {
        text-align: center;
    }

    &--dropdown,
    &--select {
        //icon

        &:not([multiple]):not([size]) {
 
            padding-right: var(--input-select-padding-right);
            background-position: var(--input-select-arrow-bg-position,right center);
            background-repeat: no-repeat;
            background-size: 1.6rem 1.6rem;
            background-image: var(--input-select-arrow-bg);
        }

        &[size],
        &[multiple] {
            height: auto;
        }



    }
    /* don't hide it, needed for screen reader */
    &--radio,
    &--checkbox {
        --input-border-radius: .1rem;
        position: absolute;
        width: var(--check-size);
        height: var(--check-size);
		border: var(--check-color) var(--check-border-width) solid;
        
        padding: 0;
        background: none;
        border: none;
    }
    &--radio {
        border-radius: 50%;
    }

    &--textarea {
        min-height: none;
        resize: vertical;

    }


}


.label {
    font-size: var(--label-font-size);
    line-height: var(--label-line-height);
    letter-spacing: var(--label-letter-spacing);
    color: var(--label-color, currentColor);
    padding: var(--label-padding);
    font-weight: var(--label-font-weight,bold);
    text-transform: var(--label-text-transform);

    &--legend {
        margin-bottom: 1.5rem;
        padding: var(--label-legend-legend-padding);
        font-size: var(--label-legend-font-size);
        line-height: var(--label-legend-line-height);
        letter-spacing: var(--label-legend-letter-spacing);
        font-weight: var(--label-legend-font-weight);
    }

    &--option {
        display: block;
        padding: 0;
        margin-top: .1.5rem;
        padding-left: var(--label-option-padding-left);
        font-size: var(--label-option-font-size, inherit);
        position: relative;
        min-height: var(--check-size);
        line-height: var(--check-size);
        user-select: none;
        cursor: pointer;

        :checked + & {
            --check-color: var(--check-checked-color);
            --check-bg-color: var(--check-checked-bg-color);
        }

        &:hover {
            --check-color: var(--check-hover-color); 
        }
        
        [disabled] + &,
        :disabled + & {
            --check-color: var(--color-disabled);
            --check-bg-color: var(--color-disabled);
        }

        &::before {
            content: '';
            cursor: pointer;
            display: block;
            position: absolute;
            left: 0;
            width: var(--check-size);
            height: var(--check-size);
            border: var(--check-color) var(--check-border-width) solid;
            color: var(--check-color);
            background-color: var(--check-bg-color,currentColor);
            background-image: var(--check-bg-image,none);
            transition: var(--check-transition);
            border-radius: .2rem;
            background-size: 100% 100%;
            background-repeat: no-repeat;

            [type=radio] + & {
                border-radius: 50%;
                background-image: none;
				background-image: var(--check-radio-bg-image,none);
            }

           
        }

      
    }


}

/* error */

.error,
.fui-error {
    //--label-color: var(--color-error);
    --input-color: var(--color-error);
    --input-select-arrow-bg: var(--input-select-arrow-error);
    --input-border-color: var(--color-error);
    --input-background-color: var(--color-error-transparent);
    --input-focus-border-color: var(--color-error);
}
