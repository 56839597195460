@use "../_base/svg";
@use "../_config/colors";
@use "../_base/typography";
@use "../mixins/background-svg";
@use "../mixins/mq";

:root {
	--action-color: var(--color-white);
	--action-bg-color: var(--color-primary-1);
	--action-after-bg-color: var(--color-black);
	--action-size: 4rem;
	--action-border-radius: 5rem;
	--action-disabled-color: var(--color-secondary-1);
	--action-before-right: var(--action-size);
	--action-padding-block: .7rem;
	--action-padding-inline: 1.7rem;
}

@include mq.mq($from: large) {
	:root {
		--action-size: 5rem;
	}
}

.action {
	--outline-color: var(--color-black);

	position: relative;
	display: inline-flex;
	gap: 2rem;
	justify-content: center;
	align-items: center;
	max-width: 100%;
	overflow: visible;
	text-decoration: none;
	transition: color var(--animationDuration) var(--animationEase);
	cursor: pointer;
	color: var(--action-color);
	border: none;
	min-height: var(--action-size);
	border-radius: var(--action-border-radius);
	padding: var(--action-padding-block) var(--action-padding-inline);
	//min-width: var(--action-min-width,18rem);
	font-size: 1.6rem;
	line-height: 1.5625;
	font-weight: 600;
	z-index: 1;
	padding-right: calc(var(--action-size) + var(--action-padding-inline));
	vertical-align: center;
	margin: var(--action-margin, 0);


	&:after {
		content: "";
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: -2;
		left: auto;
		color: var(--action-after-color, currentColor);
		width: var(--action-size);
		height: var(--action-size);
		background-color: var(--action-after-bg-color);
		border-radius: var(--action-border-radius);
		border: .1rem solid var(--action-border-color, transparent);
		transition: border-color var(--animationDuration) var(--animationEase), background-color var(--animationDuration) var(--animationEase);
		will-change: border-color, background-color;
		top: 50%;
		top: calc(50% - (var(--action-size) / 2));
	}

	&:before {
		content: "";
		display: var(--action-before-display,block);
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		right: var(--action-before-right, 0);
		z-index: -1;
		background-color: var(--action-bg-color);
		transition: right var(--animationDuration) var(--animationEase);
		will-change: right;
		border-radius: var(--action-border-radius);
	}


	&[disabled],
	&.disabled,
	&--disabled,
	&:disabled {
		--action-bg-color: var(--action-disabled-color);
		--action-after-bg-color: var(--action-bg-color);
		--action-before-right: 0;
		--spinner-color: var(--color-white);
		--action-icon-color: var(--color-white);
	}

	.icon {
		width: var(--action-size);
		height: var(--action-size);
		position: absolute;
		top: 50%;
		top: calc(50% - (var(--action-size) / 2));
		right: 0;
		color: var(--action-icon-color, currentColor);
		
	}


	&__text {
		text-indent: var(--action-text-indent, normal);
		position: var(--action-text-position, static);
		text-overflow: ellipsis;
		white-space: var(--action-text-white-space);
		overflow: hidden;
	}

	&--block {
		display: flex;
	}

	a:hover &,
	a:focus &,
	&.active,
	&:hover,
	&:focus {
		--action-before-right: 0;
		--spinner-color: var(--color-white);
		--action-icon-color: var(--color-white);
	}

}

//use this class if you can not add the svg
//Drawback, no fill color transition 
.action--no-svg {

	@include background-svg.to-custom-property(svg.$svg-circle, colors.$color-black,'--action-bg-circle');
	@include background-svg.to-custom-property(svg.$svg-action-arrow, colors.$color-white,'--action-arrow-right');

	background-repeat: no-repeat;
	background-image: var(--action-bg-circle);
	background-position: right center;
	background-size: contain;
	&:after {
		background-color: transparent;
		z-index: 1;
		background-image: var(--action-arrow-right);
		background-repeat: no-repeat;
		background-size: cover;
	}
}

.action-fab,
.action-secondary {
	--action-before-display: none;
	--action-color: var(--color-primary-1);
	--action-after-bg-color: transparent;
	--action-border-color: currentColor;
	--action-icon-color: var(--color-primary-1);
	--action-padding-inline: .7rem;

	a:hover &,
	a:focus &,
	&.active,
	&:hover,
	&:focus {
		--action-after-bg-color: var(--color-primary-1);
		--action-icon-color: var(--color-white);
	}

	&[disabled],
	&.disabled,
	&--disabled,
	&:disabled {
		--action-color: var(--action-disabled-color);
		--action-border-color: var(--action-disabled-color);
		--action-icon-color: var(--action-disabled-color);
		--action-after-bg-color: transparent;
	}



}


.action-fab {
	--action-padding-inline: 0rem;
	--action-padding-block: 0rem;

	--action-min-width: none;
	--action-text-indent: -9999rem;
	--action-text-position: absolute;
	--action-padding: 0;
}


.hs-button {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	transition: color var(--animationDuration) var(--animationEase);
	cursor: pointer;
	color: var(--action-color);
	border: none;
	min-height: var(--action-size);
	border-radius: var(--action-border-radius);
	padding: var(--action-padding-block) var(--action-padding-inline);
	font-size: 1.6rem;
	line-height: 1.5625;
	font-weight: 600;
	padding-right: calc(var(--action-size) + var(--action-padding-inline));
	vertical-align: center;
	background-color: var(--action-bg-color);
	padding: var(--action-padding-block) var(--action-padding-inline);
}

.action-secondary {}


.action-secondary--inverted {

}

/* super custom for team */
.action-collapse {
	--action-text-white-space: nowrap;

	--action-icon-color: var(--color-black) !important;
	--action-before-right: var(--action-size) !important;

	overflow: hidden;
	max-width: 32rem;

	transition: color var(--animationDuration) var(--animationEase), max-width var(--animationDuration) var(--animationEase) .2s, padding var(--animationDuration) var(--animationEase) .2s;
	

	&:before {
		left: -5rem;
	}

	&:not(.active):not(:hover):not(:focus) {
		--action-padding-inline: 0rem;
		--action-icon-color: var(--color-white) !important;
		--action-before-right: 0 !important;
		max-width: 0;
	}
}



.action-download {
	a:hover &,
	a:focus &,
	&.active,
	&:hover,
	&:focus {
		--action-before-right: 0;
		--spinner-color: var(--color-white);
	}
}



// dark bg
.dark {
	--action-after-bg-color: var(--color-white);
	--action-icon-color: var(--color-black);
	--spinner-color: var(--color-primary-1);

	.action {
		--outline-color: var(--color-white);	
	}

	.action-fab,
	.action-secondary {
		--action-color: var(--color-white);
		--action-icon-color: currentColor;
		//--action-border-color: currentColor;

		&.active,
		&:hover,
		&:focus {
			--action-icon-color: var(--color-black);
			--action-after-bg-color: currentColor;
		}

		&[disabled],
		&.disabled,
		&--disabled,
		&:disabled {
			--action-color: var(--action-disabled-color);
			--action-icon-color: var(--action-disabled-color);
			--action-after-bg-color: transparent;
		}
	}

}




.action--reserved {
    transform: scaleX(-1);

	.action__text {
		transform: scaleX(-1);
	}
  }
  
  .action--column {
	padding: 0 0 calc(var(--action-size) + var(--action-padding-inline));

	&:after,
	.icon {
		top: auto;
		bottom: 0;
		left: 50%;
		right: auto;
		transform: translate(-50%);

	}
  }
  
  
  .action--center {
	display: flex;
	margin: 0 auto;
  }
  
  