@use "../_base/svg";
@use "../_config/colors";
@use "../_base/typography";
@use "../utils/visually-hidden";
@use "../mixins/background-svg";
@use "../mixins/mq";
@use "../mixins/col";


.c-hubspotForm__text {
  margin-bottom: var(--spacing-50);
}
.c-hubspot__wrapper {
  background-color: var(--color-primary-2);
  border-radius: 1.7rem;
  -webkit-box-shadow: 2px 20px 60px -8px rgba(14,43,45,.4);
  box-shadow: 2px 20px 60px -8px rgba(14,43,45,.4);
  color: var(--color-white);
  margin-left: -1.2rem;
  margin-right: -1.2rem;
  max-width: 134.4rem;
  position: relative;
  width: calc(100% + 2.4rem);
  @include mq.mq($from: large) {
    background: -webkit-gradient(linear,left top,right top,from(var(--color-primary-2)),color-stop(50%,var(--color-primary-2)),color-stop(50%,var(--color-white)),to(var(--color-white)));
    background: linear-gradient(to right,var(--color-primary-2) 0,var(--color-primary-2) 50%,var(--color-white) 50%,var(--color-white) 100%);
    display: grid;
    grid-template-areas: "text embed";
    grid-template-columns: 45rem 1fr;
    height: 638px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
}

.c-hubspot__text {
  text-align: center;
}
.hbspt-form {
  fieldset {
    max-width: none!important;
    ul.hs-error-msgs {
      margin-top: 1rem;
    }

    &.form-columns-1 {
      display: grid;
      grid-column-gap: 1rem;
      grid-template-columns: repeat(1, 1fr);
      @include mq.mq($from: large) {
        grid-column-gap: 5rem;
      }
      .hs-input {
        width: 100%!important;
      }
    }
    &.form-columns-2 {
      display: grid;
      grid-column-gap: 1rem;
      grid-template-columns: repeat(1, 1fr);

      @include mq.mq($from: large) {
        grid-column-gap: 5rem;
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
  .hs-submit {

    margin: auto!important;
    .hs-button.primary {

    }
  }
}

.hs-form-field {

  margin-bottom: var(--field-group-margin-bottom);
  width: 100%!important;

  .input {
    border: .1rem var(--input-border-color) solid;
    font-size: var(--input-font-size, inherit);
    line-height: 1.5;
    border-width: 0 0 .1rem 0;
    margin-right: 0!important;

    > input, > textarea {

      position: relative;
      display: block;
      min-height: 6rem;
      max-width: 100%;
      border: 0;
      width: 100%!important;
      padding: 1.6rem;
      border-radius: var(--field-border-radius, 0);
      background-color: var(--field-background-bg);
      border: .1rem var(--field-border-color) solid;
      margin-right: 0!important;

      &--select {


        &-bigger {
          font-size: var(--font-size-label);
        }
      }

      &--file {

      }

      &--big {
        --field-border-radius: var(--border-radius);
        --label-text-transform: none;
        --input-border-color: var(--color-grey-100);
        --input-font-size: 2rem;
        --label-margin-bottom: 2.5rem;
        margin-bottom: .5rem;
        text-align: center;
        padding: 2.5rem;

      }


    }

    >textarea {
      min-height: 15rem;
    }
  }

  > label {
    position: relative;
    display: block;
    text-transform: var(--label-text-transform, uppercase);
    font-size: 1.6rem;
    line-height: 1.5625;
    margin-bottom: var(--label-margin-bottom, .7rem);
    letter-spacing: .05rem;
    font-weight: 700;

    &::before {
      content: "";
      display: none;
      width: 3rem;
      aspect-ratio: 1;
      background-color: var(--color-primary-1);
      border-radius: 50%;
      @include background-svg.to-custom-property(svg.$svg-x, colors.$color-white, '--error-bg');
      background-image: var(--error-bg);
      background-size: 1.7rem;
      background-position: center center;
      background-repeat: no-repeat;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-0.4rem, calc(6.3rem - 50%));
      pointer-events: none;

      @include mq.mq($from: large) {
        transform: translate(4rem, calc(6.3rem - 50%));
      }

      .field-group--has-errors & {
        display: block;
      }
    }
  }
}
.inputs-list {

  margin: 0;
  list-style: none;
  .hs-form-checkbox{
    padding: 0;
    &::marker {
      display: none;
    }
    label {
      span {
        text-transform: uppercase;
        font-weight: var(--font-weight-bold);
        font-size: 1.6rem;
        padding-left: 1.5rem;
        letter-spacing: 0.1rem;
        line-height: 1.5625;
      }
    }
  }
}
.hs-form-checkbox-display input[type="checkbox"] {
  display: none;
}


.hs-form-checkbox-display {
  position: relative;
  cursor: pointer;
  padding-left: 25px;
}


.hs-form-checkbox-display:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 2.5rem;
  height: 2.5rem;
  border: 0.2rem solid var(--color-primary-1);
  background-color: #fff;
  border-radius: 0.03rem;
}


.hs-form-checkbox-display input[type="checkbox"]:checked + span:before {
  content: '';
  width: 1.7rem;
  height: 1.7rem;
  position: absolute;
  top: 0.4rem;
  left: 4px;
  font-size: 16px;
  background-color: var(--color-primary-1);
}


