@use "../_config/colors";
@use "../mixins/mq";

$sizes: h1, h2, h3, h4, h5, h6;

@each $size in $sizes {
  #{$size},
  .#{$size} {
    display: block;
    font-size: var(--font-size-#{$size});
    line-height: var(--line-height-#{$size});
    font-weight: var(--font-weight-#{$size});
    letter-spacing: var(--letter-spacing-#{$size});
    margin-top: var(--spacing-#{$size});
   // margin-bottom: var(--spacing-#{$size});

    .no-margin & {
      margin-top: 0;
    }

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .font-size-#{$size} {
    font-size: var(--font-size-#{$size});
    line-height: var(--line-height-#{$size});
    letter-spacing: var(--letter-spacing-#{$size});
    font-weight: var(--font-weight-#{$size});
  }
}

strong,
b,
.font-weight-700 {
  font-weight: 700;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-400 {
  font-weight: 400;
}

.text-align-center {
  text-align: center;
}

.uppercase {
  text-transform: uppercase;
}

.font-color-white {
  color: var(--color-white);
}

blockquote {
  margin-top: 5rem;
  margin-bottom: 5rem;
  @extend .font-size-h3;
  quotes: "«" "»";
  color: var(--color-primary-1);
  font-weight: 300;

  &:before {
    content: open-quote;
  }

  &:after {
    content: close-quote;
  }

  @include mq.mq($from: large) {
    margin-top: 12rem;
    margin-bottom: 12rem;
  }
}

figcaption {
  padding-top: 2rem;
  text-align: left !important;
  @extend .font-size-h5;
}

::marker {
  color: var(--color-primary-1);
  @extend .font-weight-700;
}

ul, ol {
  margin-left: 1em;
  margin-top: 8rem;

  @include mq.mq($from: medium) {
    margin-top: 8rem;
  }
}

li {
  padding-left: 1.1rem;
  margin-top: var(--li-margin-top);

  &:not(:first-child) {
    --li-margin-top: 0.8rem;

    @include mq.mq($from: large) {
      --li-margin-top: 1.3rem;
    }
  }
}

p {

  margin-top: var(--paragraph-spacing-top);

  &:first-child {
    --paragraph-spacing-top: 0;
  }
}
h5, .h5 {
  text-transform: uppercase;
  color: var(--color-secondary-1);
}

.text-block {
  margin-bottom: var(--text-block-margin-bottom);
}

p {
  .action:not(:last-child){
    --action-margin: 0 0 .2rem;
  }
}